// ScrollSpy
var scrollSpy = new bootstrap.ScrollSpy(document.body)

// Swiper
var swiper = new Swiper('.swiper', {
    // clickable: true,
    direction: 'vertical',
    slidesPerView: 3,
    initialSlide: 1,
    centeredSlides: true,
    mousewheel: true,
    slideToClickedSlide: true,
    on: {
        slideChange: function (s) {
            if (s && s.slides && s.slides.length > 0) {
                var id = s.slides[s.realIndex].dataset.id
                updateSlideContent(id)
            }
        }
    }
})

var swiperButton = $('.js-slider-button')
swiperButton.click(function(e){
    e.preventDefault()
    var id = $(this).parent().attr('data-id')
    updateSlideContent(id)
    updatePagePosition()
})


var closeDescription = $('.js-close-description')
closeDescription.click(function(e){
    e.preventDefault()
    $('.content').addClass('hide')
})

function updateSlideContent(id) {
    $('.content').removeClass('hide')
    $('.content > div').addClass('hide')
    $(`#content-${id}`).removeClass('hide')
} 

function updatePagePosition() {
    var scrollTop = $('.swiper').offset().top - 130
    window.scrollTo(0, scrollTop)
} 

$(document).ready(function(){
    window.scrollTo(0, 0)
})


// Masks
var phone = $('.phone')
if (phone.length > 0) {
    var options =  {
        onKeyPress: function(input, e, field, options) {
            var masks = ['(00) 0000-00009', '(00) 00000-0000']
            var mask = (input.length > 14) ? masks[1] : masks[0]
            phone.mask(mask, options)

            if (input.length < 14) {
                setInvalidClass('.phone')
            } else {
                setValidClass('.phone')
            }
    }}
    phone.mask('(00) 00000-0000', options)
}

function clearArabeChar(text) {
    return text.replace(/[\u0600-\u06FF]/g, '').trim()
}

var capitalized = $('.capitalized')
if (capitalized.length > 0) {
    capitalized.on('change', function () {
        let nameSplit = $(this).val().replace(/[`¨´~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/[0-9]/g, '').toLowerCase().split(' ')

        for (let i = 0; i < nameSplit.length; i++) { 
            if (!['de', 'da', 'do', 'dos', 'das', 'del'].includes(nameSplit[i]) && nameSplit[i] != '') {
                nameSplit[i] = nameSplit[i][0].toUpperCase() + nameSplit[i].substring(1)
            }
        }

        let name = clearArabeChar(nameSplit.join(nameSplit.length > 1 ? ' ' : ''))

        $(this).val(name)
    })
}

var capitalized2 = $('.capitalized2')
if (capitalized2.length > 0) {
    capitalized2.on('change', function () {
        let nameSplit = $(this).val().replace(/[`¨´~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/[0-9]/g, '').toLowerCase().split(' ')

        for (let i = 0; i < nameSplit.length; i++) { 
            if (!['de', 'da', 'do', 'dos', 'das', 'del'].includes(nameSplit[i]) && nameSplit[i] != '') {
                nameSplit[i] = nameSplit[i][0].toUpperCase() + nameSplit[i].substring(1)
            }
        }

        let name = clearArabeChar(nameSplit.join(nameSplit.length > 1 ? ' ' : ''))

        $(this).val(name)
    })
}

var lowercase = $('.lowercase')
if (lowercase.length > 0) {
    lowercase.on('change', function () {
        let value = $(this).val().toLowerCase()

        $(this).val(value)
    })
}

var global_phone = $('.global-phone')
if (global_phone.length > 0) {
    mask('.global-phone')
}

// Form
$('.js-send-form').click(function(e) {
    const formData = {
        firstname: $('#firstname').val(),
        lastname: $('#lastname').val(),
        email:    $('#email').val(),
        phone:    $('#phone').val(),
        city:     $('#city').val(),
        agree:    $('#agree').is(':checked') ? 1 : 0,
        language: $('#language').val(),
        origin:   $('#origin').val()
    }

    let validPhone = true
    if (formData.language == 'en') {
        validPhone = phoneValid(formData.phone, '.global-phone')
    } else {
        validPhone = formData.phone.length > 13
    }

    const form = document.querySelector('.form')
    if (form.checkValidity() && validPhone) {
        e.preventDefault()
        e.stopPropagation()
    
        const button = $(this)
        button.addClass('is-loading')

        fetch('https://eventos.ccab.orbital.company/api/contacts', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        })
        .then(function(data) {
            if (data.status == 201) {
                $('.js-form-message').fadeIn(600) 
                button.removeClass('is-loading')
                window.scrollTo(0, document.body.scrollHeight)
            } else {
                console.log('Error')
            }
        })
        .catch(function(error) {
            console.error('Error:', error)
        })
    }
})
